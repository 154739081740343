p {
    word-break: normal !important;
    overflow-wrap: normal !important;
    white-space: normal !important;
    hyphens: none !important;
  }

  /* Style for the category headers */
.category-programming {
    font-weight: bold;
    color: #3498db; /* Light blue for Programming Languages */
  }
  
  .category-software {
    font-weight: bold;
    color: #e74c3c; /* Red for Software */
  }
  
  .category-fabrication {
    font-weight: bold;
    color: #1e8449; /* Even darker green */
}
  

.about_background {
    /* background-color: black; */
     /* background-color: var(--about-background-color);  */
     background-color: var(--primary-color);

}
.sec_sp {
    margin-bottom: calc(3rem + 5.128vw)
}

.table td,
.table th {
    color: var(--text-color);
}

.t_border {
    border-color: var(--text-color) !important;
}

.progress-title {
    font-size: 16px;
    font-weight: 700;
    margin: 15px 0 20px;
    font-family: 'Raleway';
}

.progress {
    height: 5px;
    background: var(--secondary);
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 30px;
    overflow: visible;
}

.progress .progress-bar {
    position: relative;
    background: var(--text-color);
    animation: animate-positive 2s;
    overflow: visible;
    opacity: 0.9;
}

.progress .progress-value {
    position: absolute;
    top: -30px;
    right: 8px;
    font-size: 17px;
    font-weight: bold;
    font-style: italic;
    color: var(--text-color);
}

@-webkit-keyframes animate-positive {
    0% {
        width: 0%;
    }
}

@keyframes animate-positive {
    0% {
        width: 0%;
    }
}

.section-title {
    font-size: 45px;
}

.service__title {
    padding: 8px 0;
    border-bottom: solid 2px var(--secondary-color);
}

.service-section .service-category-title {
    padding-bottom: 4px;
}


/*! CSS Used keyframes */

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.course-list ul {
    list-style-type: disc; /* Adds bullet points */
    padding-left: 20px; /* Adds space before bullet points */
  }
  
  .two-columns {
    columns: 2; /* Creates two columns */
    column-gap: 40px; /* Adjusts the gap between columns */
  }
  
  .two-columns li {
    margin-bottom: 5px; /* Adds space between list items */
  }
  