:root {
    --bg-color: #0c0c0c;
    --primary-color: #0d0d0d;
    --secondary-color: #fff;
    --text-color: #fff;
    --text-color-2: #fff;
    --text-color-3: rgb(204, 0, 0);
    --overlay-color: rgb(12 12 12 / 63%);
    --umich-color: #FFCB05;
    --umich-secondary:#00274C;
    --upenn-color: #08efff;
    --major-color:  rgb(238, 57, 196);
    --bg-secondary-color: #1d222b	;
    --about-background-color: #313131;
   
   
    --particles-link: https://i.imgur.com/pSrXOsd.png;
}

[data-theme="light"] {
    --bg-color: #d3d6dd;
    --primary-color: #d3d6dd;
    --secondary-color: #000;
    --text-color: #000;
    --text-color-2: #000;
    --text-color-3: rgb(204, 0, 0);
    --overlay-color: rgb(255 255 255 / 70%);
    --umich-color: #00274C;
    --umich-secondary:#FFCB05;
    --upenn-color: #990000;
    --major-color: #033d07;
    --bg-secondary-color: #b0b4bb	;
    --about-background-color: #C0C0C0;


    --particles-link: https://i.imgur.com/pSrXOsd.png;


}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    background-color: var(--bg-color);
    color: var(--text-color);
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: 60px;
    /* border-left: 10px solid var(--primary-color); */
    /* border-right: 10px solid var(--primary-color); */
}

ul {
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Marcellus;
}

a,
a:hover {
    color: var(--text-color);
}

p {
    word-break: break-word;
    hyphens: auto;
}

.ovhidden {
    overflow: hidden;
}

.text_2,
.text_2:hover {
    color: var(--text-color-2);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor__dot div {
    z-index: 999999 !important;
}

.cursor__dot div:last-child {
    background-color: var(--umich-color) !important;
}

.cursor__dot div:first-child {
    filter: invert(1);
    background-color: var(--umich-color) !important;
}

.color_pr {
    color: var(--primary-color) !important;
}

.color_sec {
    color: var(--secondary-color);
}