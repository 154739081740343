
.divider {
    height: 2px;
    width: 100vw;
    background-color: #333;
    margin: 20px 0; 
    position: relative;
    animation: slideIn 1s ease-out forwards;  
  }
  

  @keyframes slideIn {
    from {
      left: -100%;
    }
    to {
      left: 0;
    }
  }
  