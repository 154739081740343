section {
    flex: 1 0 auto;
    position: relative;
    width: 100%;
    -webkit-transition: all .5s ease-in;
    -o-transition: all .5s ease-in;
    transition: all .5s ease-in;
}


.has-first-color {
    color: var(--primary-color);
}

.btn-portfolio {
    background: var(--primary-color);
    border-radius: 0;
}

.btn-portfolio a {
    color: #000;
    text-decoration: none;
}

.btn-about a {
    color: var(--text-color);
    text-decoration: none;
}



/* .intro_text {
    position: relative;
    align-items: center; 
    justify-content: center; 

} */



.intro::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;                /* Position the left edge of the element at the center */
    width: 50vw;
    height: 100%;             /* Assuming you want it to span the entire height of the parent */
    background-color: var(--primary-color);
    filter: blur(100px);
    opacity: 0.8;
    z-index: -1;
    transform: translateX(-50%); /* Offset the element to the left by half of its width */
}


/* .intro_text {
    display: flex;
} */
/* 
@media (max-width:991.98px) {
    .intro_sec {
        display: block;
        height: auto!important;
    }
} */

 /* .text,
 .h_bg-image {
    width: 50%;
} */

/* @media (max-width:991.98px) {
    .intro_sec .text,
    .intro_sec .h_bg-image {
        width: 100%;
    }
} */

/* .intro {
    max-width: 600px;
    margin: 0 auto;
} */

/* .intro {
    max-width: 80vw;
    margin: 0 auto;
}
 */


/* @media (max-width:991.98px) {
    .intro_sec .intro {
        max-width: 700px;
        padding-left: 20px;
        padding-right: 20px;
    }
} */

/* .intro_sec .intro .feature .wrap-icon {
    background: 0 0!important;
    width: auto;
    height: auto;
    margin-bottom: 0;
} */

.intro .feature .wrap-icon svg {
    color: #5cccc9;
}

.text_section {
    /* margin-top: -100px; */
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    height: 93vh; /*this is 100vh minus the header (7vh) */
    padding-top: 7.5vh;
}

@media (max-width: 800px) {
    .text_section {
      height: auto; 
      min-height: 100vh; 
    }
  }

@media(max-height: 700px){
    .text_section {
        height: auto; 
        min-height: 100vh; 
      }
  }

.text_section h1 {
    font-size: 30px;
    margin-bottom: 50px;
    font-weight: 700;
}

.text h3 {
    font-size: 16px;
    font-weight: 700;
}

/* .ac_btn {
    padding: 4px 19px;
    color: var(--secondary-color);
    position: relative;
    border: var(--secondary-color) 2px solid;
    transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    cursor: pointer;
    border-radius: 0;
    margin-right: 20px;
}

.ac_btn a {
    text-decoration: none;
}

.ac_btn:hover {
    box-shadow: 8px 8px 0px var(--text-color), -8px -8px 0px var(--text-color);
}

.ac_btn:hover .one {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .two {
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .three {
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .four {
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn .ring {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    transform: translate3d(0px, 90px, 0px);
}

.ac_btn .one {
    background-color: #000;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
    z-index: -4;
}

.ac_btn .two {
    background-color: var(--primary-color);
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
}

.ac_btn .three {
    background-color: var(--secondary-color);
    z-index: -2;
    transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
}

#button_p {
    background: var(--secondary-color);
    color: var(--primary-color);
}

#button_h:hover {
    color: var(--primary-color)
}
 */


/* @media (max-width:991.98px) {
    .intro_sec .h_bg-image {
        height: 600px;
        min-height: 75vh;
        margin-bottom: 30px;
    }
    .intro_sec .h_bg-image .block {
        top: unset;
        bottom: 0;
        width: 100%;
        margin: 0;
        left: 50%;
        transform: translate(-50%, 50%);
    }
}

@media only screen and (max-width: 1366px) {
    .h_bg-caption {
      margin-top: 30rem;
    }
}
   */

.emphasize-major {
    font-weight: bold;
    color: var(--major-color);
}

.emphasize-umich {
    font-weight: bold;
    color: var(--umich-color);
}

.emphasize-upenn {
    font-weight: bold;
    color: var(--upenn-color);
}

.profile_pic {
    width: 50vh;
    height: 50vh;
    object-fit: cover;
    border-radius: 50%;
    object-position: 63% 37%;
    /* border: solid; */
    box-shadow: 1px 1px 10px 2px var(--secondary-color);  /* Adjusted the box-shadow syntax */
}

.profile-flexbox {
    display: flex;
    justify-content: center;
    flex: 1;
}

.intro_description {
    margin-bottom: 10px;
}

/* 
#particles-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;  
  }*/

  