.port_title {
   color: var(--umich-color);
   margin-top: 5vh;
   font-size: 7vh;
}
  
  /* Header style when scrolled down */

.po_items_ho {
    column-width: 15em;
    -moz-column-width: 15em;
    -webkit-column-width: 15em;
    column-gap: 4rem;
    -moz-column-gap: 4rem;
    -webkit-column-gap: 4rem;
}

.po_item {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0.5rem auto;
    position: relative;
    background: var(--secondary-color);
    padding: 6px;
    border: 1px solid var(--secondary-color);
    transition: 0.3s ease;
    font-size: 0;
    min-height: 300px;
}

.po_item img {
    max-width: 100%;
}

.po_item .content {
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--overlay-color);
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
    border: 1px solid black;
}

.po_item .content {
    opacity: 0;
    transition-delay: 1s;
    transition: 0.3s ease;
    font-size: 20px;
}

.po_item:hover {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    opacity: 1;
}

.po_item:hover .content {
    opacity: 1;
    color: var(--secondary-color);
}

.po_item .content a {
    background: var(--bg-color);
    border: solid 1px var(--text-color);
    padding: 4px 8px;
    text-align: center;
    font-size: 1rem;
}

.po_item .content a:hover {
    text-decoration: none;
}

.portfolio_background{
    background-color: var(--bg-secondary-color);
    /* width: 100vw; */
    margin: 0;
    padding: 0;
}

.project-flexbox{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: center;
    border-style: solid;
}

.title-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Assuming you have two columns */
    gap: 20px; /* Space between columns */
    padding: 20px; /* Padding around the grid */
    width: 80vw; /* Ensure this is set so centering makes sense */
    margin: 0 auto; /* Center the grid container */
}

.grid-container {
    display: grid;
    /* border: solid; */
    margin: 0 auto;
    width: 80vw;
    grid-template-columns: 1fr 1fr; /* Two columns each taking up half of the container width */
    grid-template-rows: repeat(4, 1fr); /* Four rows */
    gap: 20px; /* Space between grid items */
    padding: 20px; /* Padding around the grid */
    /* border: solid; */
  }


  
.grid-container > div {
    overflow: hidden; /* Ensure content does not overflow grid items */
  }
  
  .grid-container img, .grid-container video {
    max-width: 100%; /* Ensure media elements do not cause overflow */
    height: auto; /* Maintain aspect ratio for images */
    border-radius: 20px;
  }
  
.grid-container > div {
    /* border: solid; */
    border-width: 2px;
    background-color: var(--bg-color); 
    /* border: 1px solid #ccc;  */
    padding: 20px; 
    text-align: left;
    /* height: 200px; */
    border-radius: 20px;
 

  }

  .title{
    font-weight: 600;
    font-family: Nunito, sans-serif;
    letter-spacing: 2px;
    padding: 0.5rem 0px;
    font-size: 2rem;
    color: rgb(90, 164, 224);
    text-align: center;
    
  }

  .subtitle{
     font-weight: 600;
    font-family: Nunito, sans-serif;
    letter-spacing: 2px;
    padding: 0.5rem 0px;
    font-size: 1.5rem;
    color: rgb(90, 164, 224);
    text-align: center;
  }

/* 
.portfolio-section-hardware {
    display: inline-block;
    position: relative; 
    transition: transform 0.5s ease-in-out; } */

.hardware{
    display: flex; /* Flex container to help center content */
    justify-content: flex-start; /* Start aligned to the left */
    align-items: center; /* Align vertically */
    width: 100%; /* Each grid column takes full width */
    color: var(--umich-color);
}
.software{
    display: flex; /* Flex container to help center content */
    justify-content: flex-end; /* Start aligned to the left */
    align-items: center; /* Align vertically */
    width: 100%; /* Each grid column takes full width */
    color: var(--umich-color);

}
.arrow-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns, if you have two images */
    height: 0; /* Collapses the container's height */
    overflow: visible; /* Allows content to show outside the container */
    position: relative; /* Needed to position children absolutely */
}

.arrow-grid-container img {
    position: absolute;
    /* transform: translate(-50%, -50%);  */
    width: 30vh; 
}

.hardware_arrow {
    transform: scaleX(-1) translate(-10%, -60%) rotate(10deg);
}


.software_arrow {
    transform: translate(-110%, -60%) rotate(10deg);
}

@media (max-width: 1200px){
    .port_title, .arrow-grid-container{
        display: none;
    }
    .hardware, .software{
        font-size: 30vw ;
    }
}

.video-container {
    width: 400px; 
    height: 300px; 
    overflow: hidden;
    position: relative;
  }
  
  /* .video-crop {
    position: absolute;
    top: -50px; 
    left: -100px;
  } */

  
  .video-rotate {
    transform: rotate(-90deg); /* Adjust the angle as needed */
  }
  
  /* General Project Box Styling */
.projectBox {
    margin-bottom: 40px; /* Add spacing between project sections */
  }
  
/* General styling for project boxes (desktop and larger screens) */
#projectBoxes {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); 
    grid-gap: 20px; */

    display: flex;
    gap: 20px; /* Space between columns */
    width: 80vw;
    margin: 0 auto;
    text-align: left;
    padding-bottom: 20px;
  }
  
  /* For screens smaller than 768px (mobile), stack boxes vertically */
  @media (max-width: 768px) {
    #projectBoxes {
      grid-template-columns: 1fr; /* One box per row (stacked) */
      grid-gap: 20px; /* Adjust space between stacked boxes */
    }
  
    /* Ensure images and videos take up full width on mobile */
    video, img {
      width: 100%;
    }
  }
  

  /* General grid layout for desktop and larger screens */
#projectBoxes {
  display: flex;
  gap: 20px; /* Space between columns */
  width: 80vw;
  margin: 0 auto;


}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between items in each column */
  flex: 1; /* Ensures each column takes equal space */
  max-width: 50%; /* Prevents each column from exceeding half the container width */
}

/* Each item adjusts size based on content */
.grid-item {
  padding: 20px;
  /* Optional styling */
  background-color: var(--bg-color);
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Define hardware on the left column and software on the right */
#hardwareProjects {
  grid-column: 1; /* Left column for hardware */
}

#softwareProjects {
  grid-column: 2; /* Right column for software */
}

/* Mobile-specific styling (max-width: 768px) */
@media (max-width: 768px) {
  #projectBoxes {
    grid-template-columns: 1fr; /* Single column on mobile (stacked) */
  }

  /* Hardware and software projects stacked vertically */
  #hardwareProjects, #softwareProjects {
    grid-column: 1; /* All projects will now be in a single column */
  }

  /* Remove animations */
  .portfolio-section-hardware,
  .portfolio-section-software,
  .projectBox {
    transition: none !important; /* Disable animations for mobile */
  }

  /* Ensure full-width content on mobile */
  .projectBox {
    width: 100%;
    margin-bottom: 30px; /* Space between project boxes */
  }

  /* Full-width images and videos */
  video, img {
    width: 100%;
  }

  /* Adjust title font size for mobile */
  .portfolio-section-hardware, .portfolio-section-software {
    font-size: 2rem; /* Reduce title size for mobile */
    text-align: center;
  }
}
